import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { StyledHeaderP, HeaderTextH1 } from "../sharedStyles"

const aboutConfig = {
  customers: {
    bannerImg: "/www-background.png",
    bannerBgColor: "rgba(52, 52, 52, 0.85)",
    onClickPath: null,
    bannerTxt:
      "Experience the faster and friendlier way to book a hair appointment instantly!"
  }
}

const StyledWrapperOverlay = styled.div`
  background-color: ${props => (props.overlay ? props.overlay : null)};
  width: 100%;
  height: 100%;
`

const StyledContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
`

const StyledHeaderDiv = styled.div`
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: ${props => (props.img ? `url(${props.img})` : null)};
  background-blend-mode: multiply;

  .bottom {
    height: 80px;
    background: ${props => props.theme.colors.primary};
    width: 5px;
    right: 50%;
    top: ${props => (props.isMobile ? "88%" : "92%")};
    position: absolute;
  }
`
const Header = ({
  isMobile
}) => {
  const img = aboutConfig.customers.bannerImg || null
  const overlay = aboutConfig.customers.bannerBgColor || null
  const text = aboutConfig.customers.bannerTxt || ""
  return (
    <StyledHeaderDiv img={img} isMobile={isMobile}>
      <StyledWrapperOverlay overlay={overlay}>
        <StyledContentDiv isMobile={isMobile}>
          <StyledHeaderP>WELCOME TO FLOSSIE</StyledHeaderP>
          <HeaderTextH1
            style={{ maxWidth: 420 }}
            isMobile={isMobile}
            color="#FFF"
          >
            {text}
          </HeaderTextH1>
        </StyledContentDiv>
      </StyledWrapperOverlay>
      <div className="bottom" />
    </StyledHeaderDiv>
  )
}

Header.propTypes = {
  isMobile: PropTypes.bool
}

export default Header
