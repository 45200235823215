import React from "react"

import TriedTestedSVG from "../../../../dist/about/tried-tested.svg"
import TriedTestedGreySVG from "../../../../dist/about/tried-tested.svg"
import BookSecondsSVG from "../../../../dist/about/fast-book.svg"
import BookSecondsGreySVG from "../../../../dist/about/fast-book.svg"
import GoCashlessSVG from "../../../../dist/about/cashless.svg"
import HelpSVG from "../../../../dist/about/help-review.svg"
import GetRewardedSVG from "../../../../dist/about/reward.svg"
import FaceSVG from "../../../../dist/about/service.svg"
import RibbonUpfrontCostsSVG from "../../../../dist/about/prepaid.svg"

const HowFlossieWorksRenderSVG = ({
  type = "cancellations",
  height = "100",
  width = "100",
  title = "",
  style = {}
}) => {
  const svgs = {
    tried: (
      <TriedTestedSVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    ),
    triedgrey: (
      <TriedTestedGreySVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    ),
    bookseconds: (
      <BookSecondsSVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    ),
    booksecondsgrey: (
      <BookSecondsGreySVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    ),
    cashless: (
      <GoCashlessSVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    ),
    help: <HelpSVG height={height} width={width} title={title} style={style} />,
    rewarded: (
      <GetRewardedSVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    ),
    face: <FaceSVG height={height} width={width} title={title} style={style} />,
    upfront: (
      <RibbonUpfrontCostsSVG
        height={height}
        width={width}
        title={title}
        style={style}
      />
    )
  }
  return svgs[type]
}

export default HowFlossieWorksRenderSVG
