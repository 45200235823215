import React from "react"

export const TopSVGPartner = () => (
  <div style={{ height: "150px", overflow: "hidden" }}>
    <svg
      viewBox="0 0 1201.42 156.32"
      preserveAspectRatio="none"
      style={{ height: "100%", width: "100%" }}
    >
      <path
        d="M1201.42,155.92s-231.24,7.21-471.24-39.83S0,69.05,0,69.05V0H1201.42Z"
        style={{ fill: "#FFF" }}
      />
    </svg>
  </div>
)

export const BottomSVGPartner = () => (
  <div style={{ height: "150px", overflow: "hidden" }}>
    <svg
      viewBox="0 0 1201.42 156.32"
      preserveAspectRatio="none"
      style={{ height: "100%", width: "100%" }}
    >
      <path
        d="M0,.4S231.23-6.82,471.23,40.22s730.19,47.05,730.19,47.05v69.05H0Z"
        style={{ fill: "#FFF" }}
      />
    </svg>
  </div>
)
