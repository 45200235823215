export const howitWorksList = [
  {
    id: 1,
    title: "Integrated Calendar",
    img: "/about/integrated-calendar.png",
    text:
      "Flossie connects with the booking system you already use including Timely, Kitomba, Cliniko, Zenoti, Shortcuts and more. No admin is required. Bookings drop instantly into your calendar."
  },
  {
    id: 2,
    title: "Salon Dashboard",
    img: "/about/salon-dashboard.png",
    text:
      "Realtime reporting to better understand business performance. Access and redeem your bookings. Manage your availability and services."
  },
  {
    id: 3,
    title: "Marketing & Promotions",
    img: "/about/marketing-and-promotions.png",
    text:
      "Say hello to better tools to set dynamic pricing and promotion rules across days, employees and more. Deep-link services in adverts to booking screen and track marketing campaigns through to booking."
  }
]

export const howitWorksListCustomers = [
  {
    id: 1,
    title: "Tried & Tested",
    svgIcon: "tried",
    text:
      "Buy with confidence knowing all salons are tried by customers like you first and must maintain a minimum rating and standard to be partnered with Flossie."
  },
  {
    id: 2,
    title: "Book in seconds",
    svgIcon: "bookseconds",
    text:
      "Browse however you like from thousands of services. Select your time and confirm your booking in under 30-seconds, perfect when you’re busy!"
  },
  {
    id: 3,
    title: "Go cashless",
    svgIcon: "cashless",
    text:
      "Pay upfront and create an account (for faster checkout next time) and purchase gift vouchers. Never pull your card out again!."
  },
  {
    id: 4,
    title: "Let us help you",
    svgIcon: "help",
    text:
      "Chat to us if you want something particular. Leave a review post-appointment. Easily rebook directly in Flossie."
  },
  {
    id: 5,
    title: "Get rewarded",
    svgIcon: "rewarded",
    text:
      "Make 6 bookings within 6 months to join Flossie’s free VIP programme. Exclusive pricing, test new salons with complimentary appointments and much more. ."
  }
]

export const appFeaturesList = [
  {
    id: 1,
    svgIcon: "booksecondsgrey",
    text: "Book in under 30-seconds what you want when you want."
  },
  {
    id: 2,
    svgIcon: "face",
    text: "Hair appointments for her or him."
  },
  {
    id: 3,
    svgIcon: "triedgrey",
    text: "Featuring only premium, Tried & Tested hair salons."
  },
  {
    id: 4,
    svgIcon: "upfront",
    text:
      "Know the upfront cost, read customer reviews and get exclusive offers."
  }
]
