import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { FacebookIcon } from "../../components/icons"
import WebsiteIcon from "../../components/icons/website.svg"
import InstagramIcon from "../../components/icons/instagram.svg"

const IconA = styled.a`
  text-decoration: none;
  flex: 1;
  display: block;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  display: inline-flex;
  margin-right: 15px;
  color: inherit;
  svg {
    width: 20px;
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    text-decoration: underline;
  }
`

const IconLink = ({
  type, link, isMobile, icon
}) => {
  if (!link.startsWith("http")) return null
  // const strippedText = text.replace('http://www.', '').replace('http://www.', '').replace('https://', '').replace('http://', '').replace(/\/$/,'')
  return (
    <IconA
      href={link}
      isMobile={isMobile}
    >
      {icon}
      {type}
    </IconA>
  )
}

IconLink.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  isMobile: PropTypes.bool,
  icon: PropTypes.element,
}

const SocialMediaLinks = ({ company, isMobile }) => (
  <div style={{ display: "block" }}>
    {company.website_url && (
      <IconLink
        isMobile={isMobile}
        text={company.website_url}
        type="website"
        icon={<WebsiteIcon />}
        link={company.website_url}
      />
    )}
    {company.facebook_url && (
      <IconLink
        isMobile={isMobile}
        text="Facebook"
        type="facebook"
        icon={<FacebookIcon />}
        link={company.facebook_url}
      />
    )}
    {company.instagram_url && (
      <IconLink
        isMobile={isMobile}
        text={`@${company.instagram_url.split("/")[3]}`}
        type="instagram"
        icon={<InstagramIcon />}
        link={company.instagram_url}
      />
    )}
  </div>
)

SocialMediaLinks.propTypes = {
  company: PropTypes.object,
  isMobile: PropTypes.bool,
}

export default SocialMediaLinks
