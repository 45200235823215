import styled from "styled-components"

export const StyledHeaderP = styled.p`
  font-size: ${props => (props.large ? "18px" : "15px")};
  font-weight: 600;
  text-align: ${props => (props.center ? "center" : "left")};
  margin: 0;
  margin-top: ${props => (props.margintop ? "50px" : "0")};
  color: ${props => (!props.large ? props.theme.colors.primary : "#000")};
  letter-spacing: 1px;
`

export const HeaderTextH1 = styled.h1`
  padding: 10px 20px;
  max-width: 800px;
  display: inline-block;
  font-size: ${props => (props.isMobile ? "25px" : "30px")};
  font-weight: 500;
  text-align: center;
  white-space: ${props => (props.isMobile ? "normal" : "pre-line")};
  color: ${({ color }) => color || "#000"};
`

export const StyledText = styled.p`
  font-weight: ${props => (props.normalWeight ? "400" : "600")};
  padding: ${props => (props.isMobile ? "10px" : "0")};
  font-size: ${props => (props.smallfont ? "15px" : "18px")};
  white-space: ${props => (props.preline ? "pre-line" : "normal")};
  line-height: 1.5;
  color: #000;
`
