export const initialState = {
  instantbookservices: {},
  instantbookservicecount: 0,
  isFetching: false,
  isScrolling: false,
  readyToFetch: false,
  anyMorePages: true,
  noResults: false,
  reloadResults: true,

  whatOpen: false,
  whenOpen: false,
  whereOpen: false,

  mapOpen: false,
  mapZoom: 13,
  bbox: null,
  mapCenter: {
    lat: -36.8465517,
    lng: 174.7657366
  },

  mapCompanies: []
}

const anyMorePages = action => {
  if (
    action.payload.response.count
    && action.payload.response.count > action.payload.action.payload.filter.offset
  ) {
    return true
  }
  return false
}

const mergeServiceArray = (serviceStore, services, offset) => ({
  ...serviceStore,
  [offset]: services
})

export default function myBookingsReducer(state = initialState, action) {
  switch (action.type) {
    case "HOME_CLICKED":
      return {
        ...state,
        whatOpen: false,
        whenOpen: false,
        whereOpen: false
      }
    case "INSTANT_BOOK_MAP_SET_CENTER":
      return {
        ...state,
        mapCenter: action.payload.center
      }
    case "INSTANT_BOOK_MAP_SHOW":
      return {
        ...state,
        mapOpen: true
      }
    case "INSTANT_BOOK_MAP_HIDE":
      return {
        ...state,
        mapOpen: false
      }
    case "FETCH_PROFILE_SUCCESS":
    case "FETCH_PROFILE_FAILURE":
    case "NO_STORED_CREDENTIALS":
      return {
        ...state,
        readyToFetch: true
      }
    case "FETCH_INSTANT_BOOK_SERVICES_SUCCESS":
      return {
        ...state,
        instantbookservices: mergeServiceArray(
          state.instantbookservices,
          action.payload.response.inventory,
          action.payload.action.payload.filter.offset
        ),
        instantbookservicecount: action.payload.response.count,
        anyMorePages: anyMorePages(action),
        noResults: action.payload.response.count === 0,
        mapCompanies: action.payload.response.companies || []
      }
    case "CLEAR_INSTANT_BOOK_SERVICES":
      return {
        ...state,
        noResults: false,
        instantbookservices: {}
      }
    case "FETCH_INSTANT_BOOK_SERVICES":
      return {
        ...state,
        noResults: false,
        instantbookservices: action.payload.clear
          ? {}
          : state.instantbookservices
      }
    case "RE_FETCH_INSTANT_BOOK_SERVICES":
      return {
        ...state,
        isScrolling: true
      }
    case "SET_INVENTORY_FILTER":
      return {
        ...state,
        isScrolling: false
      }
    case "CLICK_WHEN_MENU":
      return {
        ...state,
        whenOpen: !state.whenOpen,
        whatOpen: false,
        whereOpen: false
      }
    case "CLICK_WHAT_MENU":
      return {
        ...state,
        whatOpen: !state.whatOpen,
        whenOpen: false,
        whereOpen: false
      }
    case "CLICK_WHERE_MENU":
      return {
        ...state,
        whereOpen: !state.whereOpen,
        whatOpen: false,
        whenOpen: false
      }
    case "LOG_OUT":
      return {
        ...initialState,
        readyToFetch: true
      }
    default:
      return state
  }
}
