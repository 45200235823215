import Pusher from "pusher-js"

import {
  getPusherAuthEndpointUrl
} from "../api/api"

const { PUSHER_APP_KEY, PUSHER_CLUSTER, FRANCHISE_ID } = SITE_CONFIG

// Pusher on Login
const initSocket = username => {
  if (typeof window.pusherPrivateSocket === "undefined") {
    window.pusherPrivateSocket = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_CLUSTER,
      authEndpoint: getPusherAuthEndpointUrl(),
      auth: {
        params: {
          api_user: username
        },
        headers: {
          "X-Franchise-ID": FRANCHISE_ID
        }
      }
    })
  }
}

const startSocket = (dispatch, username, userId) => {
  initSocket(username)

  const presenceChannel = `private-presence-${userId}`
  const chatChannel = `private-chat-${userId}`

  /* Explicitly unbind all channel events in case this gets called more than once */
  if (window.pusherPrivateSocket.channel(presenceChannel)) {
    window.pusherPrivateSocket.channel(presenceChannel).unbind()
  }

  if (window.pusherPrivateSocket.channel(chatChannel)) {
    window.pusherPrivateSocket.channel(chatChannel).unbind()
  }

  window.pusherPrivateSocket.subscribe(presenceChannel).bind_global((name, data) => {
    dispatch({ type: "WS_MESSAGE", payload: { name, data } })
  })

  window.pusherPrivateSocket
    .subscribe(chatChannel)
    .bind_global((name, data) => {
      dispatch({ type: "WS_MESSAGE", payload: { name, data } })
    })
}


const initScrapeSocket = () => {
  if (typeof window.pusherSocket === "undefined") {
    window.pusherSocket = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_CLUSTER,
      authEndpoint: getPusherAuthEndpointUrl(),
      auth: {
        headers: {
          "X-Franchise-ID": FRANCHISE_ID
        }
      }
    })
  }
}


const startScrapeSocket = (action, payload, token, dispatch, getState) => {
  initScrapeSocket()

  const channel = `scrape-${token}`

  window.pusherSocket
    .subscribe(channel)
    .bind("pusher:subscription_succeeded", () => {
      const scrapeTypes = [
        "TRIGGER_SCRAPE",
        "FORCE_SCRAPE"
      ]

      if (scrapeTypes.includes(payload.type)) {
        dispatch({ type: "SCRAPE_AVAILABILITY", payload })
      }

      const nextTypes = ["TRIGGER_NEXT"]

      if (nextTypes.includes(payload.type)) {
        dispatch({ type: "SCRAPE_NEXT", payload })
      }

      const priceTypes = ["TRIGGER_BEST_PRICE"]

      if (priceTypes.includes(payload.type)) {
        dispatch({ type: "SCRAPE_BEST_PRICE", payload })
      }
    })
    .bind("client-scrape-event", data => {

      if (data.type === "complete" || data.type === "next" || data.type === "price") window.pusherSocket.unsubscribe(channel)

      /* If the user has moved on and initiated another scrape type event then we no longer
         need to process the response data as the UI only supports showing the results of
         one action at a time */
      if (data.token !== getState().inventoryView.scrapeToken) {
        return
      }

      dispatch({ type: "SCRAPE_WS_MESSAGE", payload: data })

      if (data.type === "next" && data.success) {
        dispatch({ type: "TRIGGER_SCRAPE" })
      }

      if (data.type === "price" && data.success) {
        dispatch({ type: "TRIGGER_SCRAPE" })
      }
    })
    .bind("client-scrape-status", data => {

      /* See comment regarding parallel scrape events in block above ^^ */
      if (data.token !== getState().inventoryView.scrapeToken) {
        return
      }

      dispatch({ type: "SCRAPE_STATUS", payload: data })
    })
}



const unsubscribePusher = profile => {
  if (typeof window.pusherPrivateSocket !== "undefined") {
    if (profile) {
      const userId = profile.id
      const channel = `private-presence-${userId}`
      const chatChannel = `private-chat-${userId}`
      // console.log('Unsubscribing', profile, channel, chatChannel)
      window.pusherPrivateSocket.unsubscribe(channel)
      window.pusherPrivateSocket.unsubscribe(chatChannel)
      window.pusherPrivateSocket.disconnect()
      delete window.pusherPrivateSocket
    }
  }
}

// insert the new dispatched actions after, by calling next() first
export default ({ dispatch, getState }) => next => action => {
  const returnValue = next(action)
  if (action.type) {
    if (action.type === "FETCH_PROFILE_SUCCESS") {
      startSocket(
        dispatch,
        action.payload.response.username,
        action.payload.response.id
      )
    }
    if (action.type === "PRE_LOG_OUT") {
      const { profile } = getState().profile
      unsubscribePusher(profile)
    }

    if (action.type === "TRIGGER_SCRAPE_SOCKET" || action.type === "TRIGGER_NEXT_SOCKET" || action.type === "TRIGGER_BEST_PRICE_SOCKET") {
      startScrapeSocket(action, action.payload, action.payload.token, dispatch, getState)
    }
  }
  return returnValue
}
