import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import TrackVisibility from "react-on-screen"

import NavBarContainer from "../NavBar/NavBarContainer"
import Header from "./components/Header"
import MetaSetter from "../../components/MetaSetter"
import { navToHome } from "./actions"
import AboutCustomer from "./AboutCustomer"

class AboutContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      isMobile,
      isTablet,
      navToHome,
      metaTitle,
      metaDescription
    } = this.props
    return (
      <div style={{ margin: 0 }}>
        <NavBarContainer />
        <MetaSetter
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        />
        <TrackVisibility>
          <Header isMobile={isMobile} />
        </TrackVisibility>

        <AboutCustomer
          isMobile={isMobile || isTablet}
          navToHome={navToHome}
        />
      </div>
    )
  }
}

AboutContainer.propTypes = {
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  navToHome: PropTypes.func,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    isTablet: state.browser.is.tablet
  }
}

const mapDispatchToProps = dispatch => ({
  navToHome: () => dispatch(navToHome())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutContainer)
