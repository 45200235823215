import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import {
  StyledHeaderP,
  HeaderTextH1,
  StyledText,
} from "./sharedStyles"
import { BottomSVGPartner, TopSVGPartner } from "./components/DivSeperatorSVGS"
import { howitWorksListCustomers, appFeaturesList } from "./data/content"
import { SimpleButton } from "../../components/forms/Inputs"
import HowFlossieWorksRenderSVG from "./components/HowFlossieWorksRenderSVG"

const StyledWrapperDiv = styled.div`
  margin: 0;
  height: 480px;
`

const StyledHowItWorks = styled.div`
  margin-top: 50px;
  background-color: #e7ecef;
  text-align: center;
  min-height: 430px;
`

const StyledAppFeaturesDiv = styled.div`
  padding-top: 50px;
  text-align: center;
  padding: 1%;
`

const StyledGetTheAppDiv = styled.div`
  margin-top: ${props => (props.isMobile ? "50px" : "-80px")};
  padding-top: ${props => (props.isMobile ? "50px" : "100px")};
  background-color: #e7ecef;
  text-align: center;
`

const StyledGetTheAppBtns = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: ${props => (props.isMobile ? "auto auto" : "auto auto auto")};
  grid-template-rows: ${props => (props.isMobile ? "auto auto" : "auto")};
  justify-items: center;
  width: auto;
  padding-top: 20px;
  padding-bottom: 30px;
`

const StyledCustomerReviews = styled.div`
  padding-top: ${props => (props.isMobile ? "100px" : "220px")};
  margin-top: ${props => (props.isMobile ? "-100px" : "-220px")}
  background-color: #fff;
  text-align: center;
  min-height: 430px;
`
const StyledCustomerReviewMain = styled.div`
  margin-top: ${props => (props.isMobile ? "50px" : "0")}
  margin-bottom: 0;
  display: inline-block;
  max-width: 1024px;
`

const StyledHowItWorksWrapper = styled.div`
  margin-top: 2.5%;
  display: flex;
  flex-wrap: ${props => (props.isMobile ? "wrap" : "initial")};
  justify-content: ${props => (props.isMobile ? "space-around" : "center")};
`
const StyledHowItWorksItem = styled.div`
  flex: ${props => (props.isMobile ? "2 0 calc(35%)" : "0 0 calc(16%)")};
  color: white;
  margin: 10px;
`

const StyledCustomerReviewImgContainer = styled.div`
  display: block;
`
const StyledCustomerReviewImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const StyledInstagramEmbed = styled.div`
  background-color: #fff;
  text-align: center;
  height: 530px;
  padding: 20px;
`

const StyledEmbedContainer = styled.div`
  margin-right: 20%;
  margin-left: 20%;
  align-text: center;
`

const StyledQuoteBlockquote = styled.blockquote`
  quotes: "\/201C";
  font-size: ${props => (props.smallfont ? "18px" : "26px")};
  white-space: pre-line;
  margin: 0;

  &:before {
    color: #000;
    content: open-quote;
    font-size: 2.5em;
    line-height: 0.1em;
    margin-right: 0.1em;
    vertical-align: -0.4em;
  }
  &:after {
    color: #000;
    content: close-quote;
    font-size: 2.5em;
    line-height: 0.1em;
    margin-left: 0.1em;
    vertical-align: -0.4em;
  }
  .h2 {
    font-size: 28px;
    margin: 0;
    padding: 0;
  }
`

const StyledSVGWrapper = styled.div`
  flex: 2;
  text-align: center;
  margin-top: ${props => (props.isMobile ? "20px" : "60px")};
  max-width: 220px;
`

const StyledAppFeatureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  align-items: center;
`

const StyledAppFeatureParent = styled.div`
  display: ${props => (props.isMobile ? "grid" : "flex")};
  flex-direction: row;
`

const StyledQuotesContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isMobile ? "column" : "row")};
  margin-right: ${props => (props.isMobile ? "0" : "20%;")}
  margin-left: ${props => (props.isMobile ? "0" : "20%;")}
`

const StyledQuoteSeperator = styled.div`
  flex: 2;
  margin-top: 50px;
  background: transparent;
  position: relative;
`

const StyledQuoteBackground = styled.div`
  position: absolute;
  z-index: 0;
  top: -15px;
  left: calc(50% - 60px);
  width: 120px;
  height: 120px;
  background-color: #e7ecef;
  border-radius: 60px;
`

const renderAppFeaturesImage = () => (
  <div
    style={{
      flex: 3,
      alignText: "center"
    }}
  >
    <StyledAppFeaturesDiv>
      <StyledHeaderP margintop center>
        FEATURES
      </StyledHeaderP>
      <img
        alt="App features"
        src="/About_Icons-Assets_device_phone.png"
        style={{ maxHeight: "600px" }}
      />
    </StyledAppFeaturesDiv>
  </div>
)

const renderAppFeatures = isMobile => (
  <StyledAppFeatureParent isMobile={isMobile}>
    {isMobile && renderAppFeaturesImage()}
    <div style={{ flex: 5 }}>
      <StyledAppFeatureWrapper isMobile={isMobile}>
        <StyledSVGWrapper isMobile={isMobile}>
          <HowFlossieWorksRenderSVG
            style={{ marginLeft: "-26px" }}
            type={appFeaturesList[0].svgIcon}
          />
          <StyledText preline center isMobile={isMobile}>
            {appFeaturesList[0].text}
          </StyledText>
        </StyledSVGWrapper>
        <StyledSVGWrapper isMobile={isMobile}>
          <HowFlossieWorksRenderSVG type={appFeaturesList[2].svgIcon} />
          <StyledText preline center isMobile={isMobile}>
            {appFeaturesList[2].text}
          </StyledText>
        </StyledSVGWrapper>
      </StyledAppFeatureWrapper>
    </div>
    {!isMobile && renderAppFeaturesImage()}
    <div style={{ flex: 5 }}>
      <StyledAppFeatureWrapper>
        <StyledSVGWrapper isMobile={isMobile}>
          <HowFlossieWorksRenderSVG type={appFeaturesList[1].svgIcon} />
          <StyledText preline center isMobile={isMobile}>
            {appFeaturesList[1].text}
          </StyledText>
        </StyledSVGWrapper>
        <StyledSVGWrapper isMobile={isMobile}>
          <HowFlossieWorksRenderSVG type={appFeaturesList[3].svgIcon} />
          <StyledText preline center isMobile={isMobile}>
            {appFeaturesList[3].text}
          </StyledText>
        </StyledSVGWrapper>
      </StyledAppFeatureWrapper>
    </div>
  </StyledAppFeatureParent>
)

const renderGetTheApp = (isMobile, navToHome) => (
  <StyledGetTheAppDiv isMobile={isMobile}>
    <StyledHeaderP center>USE FLOSSIE</StyledHeaderP>
    <HeaderTextH1>
      Flossie is available to use on the web.
    </HeaderTextH1>
    <br />
    <div style={{ display: "inline-block" }}>
      <StyledGetTheAppBtns isMobile={isMobile}>
        <SimpleButton
          href="/"
          id="nav_to_booking"
          value="Book Now"
          isMobile={isMobile}
          onClick={() => {
            navToHome()
            return false
          }}
        >
          Book Now
        </SimpleButton>
      </StyledGetTheAppBtns>
    </div>
    <StyledCustomerReviewImgContainer>
      <StyledCustomerReviewImg
        isMobile={isMobile}
        alt="Customer reviews"
        src={
          isMobile
            ? "/About_Icons-Assets_Devices_tablet_mobile.png"
            : "/About_Icons-Assets_Devices_tablet_desktop.png"
        }
      />
    </StyledCustomerReviewImgContainer>
  </StyledGetTheAppDiv>
)

const renderCustomerReview = isMobile => (
  <div>
    <StyledCustomerReviews isMobile={isMobile}>
      <StyledCustomerReviewMain isMobile={isMobile}>
        <StyledHeaderP center>CUSTOMER REVIEWS</StyledHeaderP>
        <HeaderTextH1>Why Flossie?</HeaderTextH1>

        <StyledQuoteBlockquote>
          It’s like your personal beauty planner with a wide range of treatments
          and salons that are specifically tailored to your beauty needs.
        </StyledQuoteBlockquote>

        <cite>
          <StyledText normalWeight preline={!isMobile}>
            -Rebecca, Christchurch
          </StyledText>
        </cite>
      </StyledCustomerReviewMain>

      <StyledQuotesContainer isMobile={isMobile}>
        <StyledQuoteSeperator>
          <StyledQuoteBackground />
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: "transparent"
            }}
          >
            <StyledQuoteBlockquote smallfont>
              Being able to see reviews from others takes the worry out of
              trying a new salon.
            </StyledQuoteBlockquote>
            <cite>
              <StyledText smallfont normalWeight>
                – Laura, Auckland
              </StyledText>
            </cite>
          </div>
        </StyledQuoteSeperator>

        <div style={{ width: 50 }} />

        <StyledQuoteSeperator>
          <StyledQuoteBackground />
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: "transparent"
            }}
          >
            <StyledQuoteBlockquote smallfont>
              I love that I don’t have to call. I can do it all on my phone…
              even in the middle of a meeting.
            </StyledQuoteBlockquote>
            <cite>
              <StyledText smallfont normalWeight>
                – Stacey, Wellington
              </StyledText>
            </cite>
          </div>
        </StyledQuoteSeperator>
      </StyledQuotesContainer>
    </StyledCustomerReviews>
  </div>
)

const renderMobileFeatures = isMobile => (
  <StyledHowItWorks>
    <TopSVGPartner />
    <StyledHeaderP center>ABOUT FLOSSIE</StyledHeaderP>
    <HeaderTextH1>How Flossie Works</HeaderTextH1>
    <StyledHowItWorksWrapper isMobile={isMobile}>
      {howitWorksListCustomers.map(item => (
        <StyledHowItWorksItem key={item.id} isMobile={isMobile}>
          <HowFlossieWorksRenderSVG
            type={item.svgIcon}
            title={item.title}
            height={100}
          />
          <StyledText isMobile={isMobile}>{item.title}</StyledText>
          <StyledText
            preline={!isMobile}
            normalWeight
            smallfont
            isMobile={isMobile}
          >
            {item.text}
          </StyledText>
        </StyledHowItWorksItem>
      ))}
    </StyledHowItWorksWrapper>
    <BottomSVGPartner />
  </StyledHowItWorks>
)

const renderInstagramEmbed = () => (
  <StyledInstagramEmbed>
    <StyledHeaderP center>GET SOCIAL</StyledHeaderP>
    <HeaderTextH1>Connect With Us</HeaderTextH1>
    <StyledText normalWeight preline>
      Share your Flossie experience by tagging #getflossied and #loveflossie to
      be featured.
    </StyledText>
    <StyledEmbedContainer>
      <div
        className="embedsocial-instagram"
        data-ref="3ebe9a4abe9d942fb4b8d4bb6e0e0d21fa637e09"
      />
    </StyledEmbedContainer>
    <StyledText normalWeight preline>
      Need assistance? Start a conversation via the intercom button to the
      right.
      <br />
      Alternatively, contact us via
      {" "}
      <a style={{ color: "#ef609f" }} href="mailto:help@flossie.com">
        help@flossie.com
      </a>
      {" "}
      or 0800 4 3567743
    </StyledText>
  </StyledInstagramEmbed>
)

const AboutCustomer = ({ isMobile, navToHome }) => {
  return (
    <StyledWrapperDiv>
      {renderAppFeatures(isMobile)}
      {renderGetTheApp(isMobile, navToHome)}
      {renderCustomerReview(isMobile)}
      {renderMobileFeatures(isMobile)}
      {renderInstagramEmbed()}
    </StyledWrapperDiv>
  )
}

AboutCustomer.propTypes = {
  isMobile: PropTypes.bool,
  navToHome: PropTypes.func
}

export default AboutCustomer
